const clientOnlyEvents = {
  CLICKED_DOWNLOAD: "CLICKED_DOWNLOAD" as const,
  SELECTED_NEW_WORLD: "SELECTED_NEW_WORLD" as const,

  // Welcome/Onboarding
  ANSWERED_SA_QUESTION: "ANSWERED_SA_QUESTION" as const,
  SKIPPED_SKILL_ASSESSMENT: "SKIPPED_SKILL_ASSESSMENT" as const,
  STARTED_SKILL_ASSESSMENT: "STARTED_SKILL_ASSESSMENT" as const,
  COMPLETED_SKILL_ASSESSMENT: "COMPLETED_SKILL_ASSESSMENT" as const,
  COMPLETED_SKILL_ASSESSMENT_LEVEL_2_PLUS:
    "COMPLETED_SKILL_ASSESSMENT_LEVEL_2_PLUS" as const,
  VIEWED_PAYWALL: "VIEWED_PAYWALL" as const,
};

const serverOnlyEvents = {};

export const events = {
  ...clientOnlyEvents,
  ...serverOnlyEvents,
};
